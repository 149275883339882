
import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';

import fai_logo from '../../assets/fai.png';
import inea from "../../assets/INEA.png";
import mebsz from "../../assets/MEBSZ.png";

import './menubar.css';
import SideDrawer from "../SideDrawer/index";
import Modal from '../Modal/index'
import Login from '../Auth/Login'
import { UserContext } from '../../context/UserContext';

function Menubar({setLanguage, selected, language, t  }) {

    const [isOpen, setIsOpen] = React.useState(false)
    const [show, setShow] = React.useState(false)
    const { loggedIn, setLoggedIn } = useContext(UserContext);

/* const toggleClicked = (e) => {
    if (e) {
        setLanguage('en')
    } else {
        setLanguage('hu')
    }
}; */


const openDrawerHandler = () => {
    setIsOpen(!isOpen)
}

const closeDrawerHandler = () => {
    setIsOpen(false)
}

const login = () => {
    setShow(true)
    setLoggedIn(false)
}

const supporters = <Menu style={{ display: "flex", flexDirection: "column", zIndex: "9999"}}>
    <Menu.Item style={{height: "100px"}}>
        <img className="supporterImg1"  alt="INEA" src={inea} />
    </Menu.Item>     
    <Menu.Item style={{height: "100px", }}>
        <img  className="supporterImg2"  alt="MEBSZ" src={mebsz} />
    </Menu.Item> 
  </Menu>
  return (
<>

  <nav className="menubar-buttons">
            <button onClick={login} className="loginButton">{loggedIn ? "Kijelentkezés" : "Bejelentkezés"}</button>
            <NavLink exact to="/">{t('Menubar.home')}</NavLink>
            <NavLink to='/hirek'>{t('Menubar.events')}</NavLink>
            <NavLink to='/tagszervezetek'>{t('Menubar.members')}</NavLink>
            <NavLink to="/esemenyek">{t('Menubar.calendar')}</NavLink>
            <NavLink to="/letoltheto">{t('Menubar.documents')}</NavLink>
            <NavLink to="/galeria">{t('Menubar.galery')}</NavLink>
            <Dropdown overlay={supporters}>
            <button className='supporters-btn'>
                Támogatóink, együttműködőink
            </button>
        </Dropdown>
            <a rel="noreferrer" href="https://www.mrlsz.hu/cikk/szakagi-leiras/ejtoernyos-szakag" target="_blank"><img src="https://www.mrlsz.hu/sites/default/files/mrlsz_logo2020.png" alt="FAI" className="mrlsz"/></a>
            <a rel="noreferrer" href="https://www.fai.org/commission/isc" target="_blank"><img src={fai_logo} alt="FAI" className="fai"/></a>
            <a rel="noreferrer" href="https://www.facebook.com/MagyarEjtoernyosSzovetseg" target="_blank"><FacebookIcon className="facebook" fontSize="small" /></a>
            <a rel="noreferrer" href="https://www.instagram.com/skydive.hu/" target="_blank"><InstagramIcon className="instagram" fontSize="small" /></a>
    </nav>

  <SideDrawer show={isOpen} onClick={closeDrawerHandler}>
    <ul>
        <li className="li-drawer">
                   <NavLink to='/' >Kezdőlap</NavLink>
                </li>
                <li className="li-drawer">
                    <NavLink to='/hirek'>{t('Menubar.events')}</NavLink>
                </li>
                <li className="li-drawer">
                    <NavLink to='/tagszervezetek'>Tagszervezeteink</NavLink>
                </li>
                <li className="li-drawer">
                    <NavLink to="/esemenyek">Versenynaptár</NavLink>
                </li> 
                <li className="li-drawer">
                    <NavLink to="/letoltheto">Dokumentumok</NavLink>
                </li> 
                <li className="li-drawer">
                    <NavLink to="/galeria">Galéria</NavLink>
                </li>
                <li>
                    <a href="https://www.mrlsz.hu/cikk/szakagi-leiras/ejtoernyos-szakag" rel="noreferrer"  target="_blank"><img src="https://www.mrlsz.hu/sites/default/files/mrlsz_logo2020.png" alt="FAI" className="mrlsz"/></a>
                </li>
                <li>
                    <a href="https://www.fai.org/commission/isc" rel="noreferrer" target="_blank"><img src={fai_logo} alt="FAI" className="fai"/></a>
                </li>
                <li>
                    <img src="https://www.mrlsz.hu/sites/default/files/mrlsz_logo2020.png" alt="FAI" className="mrlsz"/>
                </li>
                <li>
                    <a href="https://www.facebook.com/MagyarEjtoernyosSzovetseg" rel="noreferrer"  target="_blank"><FacebookIcon className="facebook" fontSize="small" /></a>
                </li>
                <li>
                    <a href="https://www.instagram.com/skydive.hu/" rel="noreferrer"  target="_blank"><InstagramIcon className="instagram" fontSize="small" /></a>
                </li>                                
  </ul>
  </SideDrawer>
    <button  className="drawer-button"
          onClick={openDrawerHandler}>
          Menü
          <span />
          <span />
          <span />
    </button>

    <Modal
            show={show}
            onCancel={() => setShow(false)}
            header="Belépés"
            footer={
                <button 
                    onClick={() => setShow(false)} className='document-modal-button'>
                        Bezárás
                </button>
            }
        >
        <Login callback={(x) => {setShow(x)}} />
    </Modal> 
</>
  );
}

export default withNamespaces()(Menubar);
