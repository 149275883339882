import { useRef, useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';

import './auth.css'

const Login = ({callback}) => {
    const { loggedIn, setLoggedIn } = useContext(UserContext);
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (user === 'admin' && pwd === 'teszt1') {
            setLoggedIn(true)
           // setUser('');
           // setPwd('');
           // setSuccess(true);
            callback(false)

        } else {
            setErrMsg('Hiányzó/Hibás felhasználónév/Jelszó!')
        }
        
    }

    return (
                    <div className='form'>
                        <label htmlFor="username">Felhasználónév:</label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                        />

                        <label htmlFor="password">Jelszó:</label>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                        />
                        <button onClick={handleSubmit}>Bejelentkezés</button>
                        <p>
                        <span className="line">
                            {errMsg}
                        </span>
                    </p>
                    </div>

            )}
    


export default Login