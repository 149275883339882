import React, { useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';




function Map(props) {

  const containerStyle = {
    width: "100%",
    height: '40vh',
    opacity: 0.8,
    borderRadius: "2px"
  };

    const [ selected, setSelected ] = useState({});
    const [ zoom, setZoom ] = useState();

    const onSelect = item => {
      setSelected(item);
    }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBINmIbjU3lzJBppGVNCzGPr9HjsewJE9A",
  })



  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({lat: props.lat, lng: props.lng });
    map.fitBounds(bounds);
    setMap(map)
  }, [props.lat, props.lng])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const location = {
    lat: props.lat,
    lng: props.lng 
  }

  const zoomchange = () => {
    setTimeout(() => {
      setZoom(15)
    }, 100);

    setTimeout(() => {
      setZoom(15)

    }, 100);
  }

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={zoom}
        onZoomChanged={zoomchange}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker key="location"
                position={location}
                onClick={() => onSelect(location)}
        />
        {
            selected.location && 
            (
              <InfoWindow
              position={selected}
              clickable={true}
              onCloseClick={() => setSelected({})}
            >
              <p>{selected.name}</p>
            </InfoWindow>
            )
         }
        <></>
      </GoogleMap>
  ) : null
}

export default React.memo(Map)