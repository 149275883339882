import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { CSSTransition } from 'react-transition-group';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Map from '../../components/Map'
import { members } from '../../data/members'
import { jumpareas} from '../../data/jumpareas'
import './membersinfo.css';

function MembersInfo() {
  const [clicked, setClicked] = useState(false);
  const [selected, setSelected] = useState('Albatrosz')
  const [content, setContent] = useState()
  const [contentType, setContentType] = useState()
  const [showContent, setShowContent] = useState(true)
  const [mapIsShow, setMapIsShow] = useState(false)

  useEffect(() => {
      setContent(members[0])
  }, [])


  const showDetails = (member, type) => {
    setMapIsShow(false)
    setShowContent(false)

    setTimeout(() => {
      setShowContent(true)
    }, 500);
    setClicked(!clicked)

    if (type === 'jumparea') {
        setContentType('jumparea')
    } else {
        setContentType('')
    }

    if (selected.includes(member.name)) {
      setClicked(false)
      setSelected('')
    } else {
      setSelected(member.name)
      setContent(member)
      setClicked(true)
    }
  }

  const showMap = () => {
      setMapIsShow(!mapIsShow)
  }


const membersMenu = <Menu>{members.map((member, i) => {
  return <Menu.Item key={i} onClick={() => showDetails(member)}>
          {member.name}
        </Menu.Item> 
})}</Menu>

const jumpMenu = <Menu>{jumpareas.map((jump, i) => {
  return <Menu.Item key={i} onClick={() => showDetails(jump, 'jumparea')}>
          {jump.name}
        </Menu.Item> 
})}</Menu>
  

  return (
    <>
    <Helmet>
      <title>Tagszervezetek | Ugróterületek - Skydive.hu</title>
      <meta name="description" content="events" />
    </Helmet>
    <div className="membersinfo-container">
      <div className='membersinfo-jump-content-half'>
        <div>
            <h2>Tagszervezeteink</h2>
            {members.map((member, i ) => <button key={i} onClick={() => showDetails(member)} className={selected === member.name ? 'memberinfo-btn-active' : 'memberinfo-btn'}>{member.name}</button>)}
        </div>
        <div>
            <h2>Ugróterületek</h2>
            {jumpareas.map((area, i) => <button key={i} onClick={() => showDetails(area, 'jumparea')}  className={area.name === 'DropZone Miskolc' ? 'button-wide' : 'memberinfo-btn'}>{area.name}</button>)}
        </div>
        <div>
          <h2>Szélcsatornák</h2>
          <a className="memberinfo-btn" href="https://skyward.hu/" rel="noreferrer" target="_blank">https://skyward.hu/</a>
        </div>
      </div>
      <div className='membersinfo-jump-content-dropdown'>
        <Dropdown overlay={membersMenu}>
            <button className='memberinfo-btn'>
                Tagszervezeteink
            </button>
        </Dropdown>
        <Dropdown overlay={jumpMenu}>
            <button className='memberinfo-btn'>
                Ugróterületek
            </button>
        </Dropdown>
        <a href="https://skyward.hu/" rel="noreferrer" target="_blank">Szélcsatornák</a>
      </div>
      <CSSTransition
        in={showContent}
        timeout={2000}
        classNames="member-appear-effect"
        mountOnEnter
        unmountOnExit
      >
      <div className='memberinfo-details'>
           {content && contentType !== 'jumparea' ? 
           <>
           <div className='member-contact-label'>
                <h1>{content.fullname}</h1>
                <div className='contact-card'>
                    <img src={content.img} alt='logo' className='card-logo' />
                    <section className='member-card'>
                      <div><label>Kapcsolattartó: </label><strong>{content.contactPerson.name}</strong></div>
                      <div><label>Telefonszám: </label><strong>{content.contactPerson.phone}</strong></div>
                      <div><label>Email cím: </label><strong>{content.contactPerson.email}</strong></div>
                      <div><label>Weboldal: </label><strong><a href={content.contactPerson.web} rel="noreferrer" target='_blank'>{content.contactPerson.web}</a></strong></div>
                    </section>
                </div>
            </div>
            <h2>Bemutatkozás</h2>
            <p style={{  color: "black"}}>{content.bio}</p>
            {content.departments ? 
                <ul className="minfo-ul">
                    {content.departments.map((item, i) => {
                        return <li key={i}>{item.name}</li>
                    })}
                </ul>
            : null }
            <h2>Ugróterület: </h2>
            <ul className="minfo-ul">
            {content.ugro.map((item, i) => {
                return (
                    <li key={i}>{item.label}</li>
                )
            })}
            </ul>
            <div className='member-map-contact'>
                <h2>Elérhetőség</h2>
                <h3>{content.contact.address}</h3>
                <button onClick={showMap}>{mapIsShow ? 'Térkép bezárása' : 'Mutasd a térképen!'}</button>
                {mapIsShow? <Map lat={content.contact.map.lat} lng={content.contact.map.lng}/> : null}
            </div>

            </>
             :  null}
             {content && contentType === 'jumparea' ? 
             <>
                <div className='member-contact-label'>
                    <h1>{content.name}</h1>
                    <div className='contact-card'>
                        <section>
                            <div><label>Kapcsolattartó: </label><strong>{content.contactPerson.name}</strong></div>
                            <div><label>Telefonszám: </label><strong>{content.contactPerson.phone}</strong></div>
                            <div><label>Email cím: </label><strong>{content.contactPerson.email}</strong></div>
                            <div><label>Weboldal: </label><strong><a href={content.contactPerson.web} rel="noreferrer" target='_blank'>{content.contactPerson.web}</a></strong></div>
                        </section>
                    </div>
                    <p style={{paddingTop: "20px"}}>{content.bio}</p>
                </div>
            </> : null }
      </div>
      </CSSTransition>
    </div> 
    </>
  );
}

export default MembersInfo;
