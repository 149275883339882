import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Mail } from 'react-feather'
import { AtSign} from 'react-feather'
import { Phone} from 'react-feather'
import moment from 'moment';
//import adatvedelmi from '../../assets/documents/adatvedelmi-vizjeles.docx'
import Map from '../Map'
import './footer.css'

function Footer() {

  return (
    <>
      <footer className="footer-content">
      <label>Kapcsolat</label>
        <div className="fb-page" 
                    data-tabs="timeline,events,messages"
                    data-href="https://www.facebook.com/MagyarEjtoernyosSzovetseg"
                    data-width="350" 
                    data-height="750"
                    data-hide-cover="false">
        </div>
        <div className="footer">
            <Map lat={47.617800} lng={19.197010}  />
            <div className='footer-items'>
              <ul>
                <li>Székhely: 2151, Fót Tátra u.5</li>
                <li><Mail size='15px' strokeWidth='1px'/> Levelezési cím: 2151, Fót Tátra u.5</li>
                <li><Phone size='15px' strokeWidth='1px'/>  Tel.: +36 20 922 8283</li>
                <li><AtSign size='15px' strokeWidth='1px'/> E-mail cím: <a href="mailto:info@skydive.hu">info@skydive.hu</a></li>
                <li>Bankszámlaszám: OTP 11709002-20613927</li>
                <li>Adószám: 18245275-1-13</li>
             </ul>
              <ul>
                <li>
                  <a href="#" target="_blank" rel="noopener noreferrer" download>
                    Adatvédelmi szabályzat/letöltés
                  </a>
                </li>
                <li><NavLink to='/documents'>Letölthető dokumentumok</NavLink></li>
              </ul>
            </div>
          </div>
          <div className="footer-bottom">Magyar Ejtőernyős Szövetség © {moment().year()} – Minden jog fenntartva!</div>
        </footer>
      </>
  );
}

export default Footer;
