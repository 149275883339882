import db from "../../firebase";
import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { UploadOutlined } from "@ant-design/icons";
import {
  Collapse,
  Empty,
  Tag,
  Modal,
  Input,
  Upload,
  message,
  Button,
} from "antd";
import BlogCard from "../../components/BlogCard";
import {
  onSnapshot,
  collection,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { storage } from "../../firebase";
import { ref, uploadBytes } from "firebase/storage";


import "./Blog.css";
import { useEffect, useState } from "react";
const { Panel } = Collapse;
const { TextArea } = Input;
const defaultImage =
  "https://img.freepik.com/free-photo/white-cloud-sky-background_74190-4646.jpg?w=2000";

let images = [];
const imageProps = {
  name: "file",
  action: "",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      images = info.fileList;
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
const { Search } = Input;
const Blog = (props) => {
  const [blogItems, setBlogItems] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState({});
  const [searchValue, setSearchValue] = useState();
  const [newBlogObject, setBlogObject] = useState({
    id: "",
    mainText: "",
    shortDesc: "",
    longDesc: "",
    mainImage: "",
    published: "",
    tags: [],
    link: "",
    linkName: "",
  });
  const { loggedIn } = useContext(UserContext);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "blogCollection"), (snapshot) => {
      setBlogItems(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    return unsub;
  }, []);

  const showModal = () => {
    setModalOpen(true);
  };

  const handleOk = async () => {
    const blogRef = collection(db, "blogCollection");
    let payload = { ...newBlogObject, tags: Object.values(tags) };
    await addDoc(blogRef, payload);
    /* 
    const imageRef = ref(storage, `images/${images[0].img.name}`);
    uploadBytes(imageRef, images[0]).then(() => {
     message.success(`${images[0].img.name} file uploaded successfully`);
    }) */

    setBlogObject({
      id: "",
      mainText: "",
      shortDesc: "",
      longDesc: "",
      mainImage: "",
      published: "",
      link: "",
      tags: [],
    });

    images = [];

    setModalOpen(false);
    /*     setBlogObject({...newBlogObject, tags: Object.values(tags)}) */
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <div className="blog">
      <Search
        placeholder="Keresés a cikkek között..."
        onSearch={(e) => setSearchValue(e)}
        enterButton
        style={{ paddingBottom: "20px" }}
      />


      <Collapse
        defaultActiveKey={1}
        bordered={true}
        className="custom-collapse"
      >
        {blogItems.map((item) => {
          let content = (
            <div>
              <p>
                <strong>{item.mainText}</strong>
              </p>
              {item.tags.map((tag) => {
                if (tag !== "") return <Tag color="geekblue">{tag}</Tag>;
              })}
              {item.link ? (
                <a
                  style={{ color: "#3342FF" }}
                  href={item.link}
                  target="_blank"
                >
                  {item.linkName}
                </a>
              ) : (
                <p>{item.shortDesc}</p>
              )}
            </div>
          );

          let longText;
          if (item.longDesc === "" || item.longDesc === undefined)
            longText = (
              <Empty
                description="A bejegyzéshez nincs további leírás."
                style={{ paddingRight: "250px" }}
              />
            );
          else longText = item.longDesc;

          return (
            <Panel
              style={{ padding: "10px" }}
              header={content}
              key={item.id}
              extra={item.published}
            >
              <div className="panelContent">
                <img
                  src={item.mainImage ? item.mainImage : defaultImage}
                  style={{ width: "30%", borderRadius: "10px" }}
                />
                <p style={{ width: "70%", padding: "10px", fontSize: "18px" }}>
                  {longText}
                </p>
                <p style={{ width: "100%", textAlign: "right" }}>
                  {item.shortDesc}
                </p>
              </div>
            </Panel>
          );
        })}
      </Collapse>

      <Modal
        title="Új blogbejegyzés létrehozása"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Vissza"
        okText="Feltöltés"
      >
        <h3>Címszöveg</h3>
        <Input
          placeholder=""
          onChange={(e) =>
            setBlogObject({ ...newBlogObject, mainText: e.target.value })
          }
        />
        <h3>Rövid leírás - Címszöveg mellett jelenik meg</h3>
        <Input
          placeholder=""
          onChange={(e) =>
            setBlogObject({ ...newBlogObject, shortDesc: e.target.value })
          }
        />
        <h3>Letölthető link - ha van</h3>
        <Input
          placeholder=""
          onChange={(e) =>
            setBlogObject({ ...newBlogObject, link: e.target.value })
          }
        />
        <h3>Letölthető link elnevezése - ha van</h3>
        <Input
          placeholder=""
          onChange={(e) =>
            setBlogObject({ ...newBlogObject, linkName: e.target.value })
          }
        />
        <h3>Hosszú leírás</h3>
        <TextArea
          rows={4}
          placeholder=""
          maxLength={5000}
          onChange={(e) =>
            setBlogObject({ ...newBlogObject, longDesc: e.target.value })
          }
        />
        <h3>Feltöltés dátuma</h3>
        <Input
          placeholder=""
          onChange={(e) =>
            setBlogObject({ ...newBlogObject, published: e.target.value })
          }
        />

        <h3>Tag-ek hozzáadása</h3>
        <Input
          placeholder="Sportrepülés"
          onChange={(e) =>
            setTags((prev) => {
              return { ...prev, a: e.target.value };
            })
          }
        />
        <Input
          placeholder="Ejtőernyőzés"
          onChange={(e) =>
            setTags((prev) => {
              return { ...prev, b: e.target.value };
            })
          }
        />
        <Input
          placeholder="Verseny"
          onChange={(e) =>
            setTags((prev) => {
              return { ...prev, c: e.target.value };
            })
          }
        />
        <Input
          placeholder="Aeromagazin"
          onChange={(e) =>
            setTags((prev) => {
              return { ...prev, d: e.target.value };
            })
          }
        />
        <Input
          placeholder="stb."
          onChange={(e) =>
            setTags((prev) => {
              return { ...prev, f: e.target.value };
            })
          }
        />

        <h3>Képek feltöltése</h3>
        <Upload {...imageProps}>
          <Button icon={<UploadOutlined />}>
            Kattintson ide a képek kiválasztásához
          </Button>
        </Upload>
      </Modal>

      {loggedIn ? (
        <button
          className="addNewBtn"
          onClick={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          Új bejegyzés hozzáadása
        </button>
      ) : null}
      
    </div>
  );
};

export default Blog;
