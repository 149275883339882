// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBXoMtRIogz23CTQjoBrfrgaCWW3dC_lLM",
  authDomain: "skydiveadmin.firebaseapp.com",
  projectId: "skydiveadmin",
  storageBucket: "skydiveadmin.appspot.com",
  messagingSenderId: "436646032319",
  appId: "1:436646032319:web:ef51c6ec0895f4a116e70a",
  measurementId: "G-EFHTLBSHDH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);

export default getFirestore();