export const jumpareas = [
    {
        name: 'Békéscsaba ',
        bio: '',
        contactPerson: {
            name: '',
            phone: '',
            email: '',
            web: '',
            info: ''
    }
    },
    {
        name: 'Dunaújváros ',
        bio: <div><h3 style={{textDecoration: "underline"}}>Dunaújváros</h3> <br></br> Cessna 182 repülőgéppel dolgozó ugróterület, mely tandemugrásra és sportugrásra is lehetőséget biztosít. A repülőgép kapacitása miatt sportugrásokra elsődlegesen péntekenként van lehetőség, hétvégén inkább a repülőtéri hangulat kiélvezése és egy-egy ugrás végrehajtása lehetséges. Az üzemek egyeztetése, szervezése az ugróterület FB csoportjában történik: <a target="_blank" href="https://www.facebook.com/groups/skydivedunaskydivers">https://www.facebook.com/groups/skydivedunaskydivers</a> <br></br><br></br> A reptéren üzemel az Ittas Ürge Bár, mely étellel és itallal is várja a betérőket. <a target="_blank" href="https://www.facebook.com/IttasUrge ">https://www.facebook.com/IttasUrge </a> WC, zuhanyzó, sátorozási lehetőség és bérelhető szobák is várják az ide látogatókat. <br></br><br></br>Lehetőség van motoros és vitorlázó repülővel is kipróbálni a repülést. <br></br><br></br> Árak (2022.03.04) <br></br><br></br> 1500 m – 6000 Ft <br></br><br></br> 3500 m – 12500Ft <br></br><br></br> 4000 m – 14500 Ft <br></br><br></br>  
        <h3 style={{textDecoration: "underline"}}>No Para! Ejtőernyős Iskola </h3><br></br> A kezdő ejtőernyős képzéstől az AFF oktató képzésig szinte minden képzést végzünk, melyhez a jogi hátteret a Baranya Ejtőernyős Sportegyesület Képző Szervezet adja. A sporttal ismerekedők az <a target="_blank" href="https://ejtoernyostanfolyam.hu/ ">https://ejtoernyostanfolyam.hu/ </a> weboldalon találnak részletes információt tanfolyamainkról, melyeket a Dunaújvárosi és a Siófoki Repülőtéren bonyolítunk le. <br></br><br></br> Közösségi életünket a Baranya Ejtőernyős Sportegyesület keretein belül szervezzük. <br></br><br></br>  Kapcsolattartó: Farkas Gábor <br></br><br></br> E-mail: gabor@ejtoernyostanfolyam.hu <br></br><br></br> Telefon: 30/385-0220 <br></br><br></br> Weboldal: <a target="_blank" href="https://ejtoernyostanfolyam.hu/">ejtoernyostanfolyam.hu</a></div>,
        contactPerson: {
            name: 'Farkas Gábor',
            phone: '30/385-0220',
            email: 'gabor@ejtoernyostanfolyam.hu',
            web: 'https://www.facebook.com/groups/skydivedunaskydivers',
            info: ''
    }
    },
    {
        name: 'Őcsény ',
        bio: '',
        contactPerson: {
            name: '',
            phone: '',
            email: '',
            web: '',
            info: ''
    }
    },
    {
        name: 'Börgönd ',
        bio: 'A Börgöndi repülőtéren folyamatos repülések és ejtőernyős ugrások vannak. Képzések folynak merevszárnyú, vitorlázó, sárkányos és ejtőernyős vonatkozásban. Tandemugrás folyamatosan működik. Általában hétvégeken vannak az üzemnapok, de igény szerint hétközben is. Az eseti légtér FL140 magasságig, UTC 06:00-tól napnyugtáig engedélyezett. C-182 típusú repülőgép/gépek állnak az ejtőernyősök rendelkezésére. Célbaugráshoz (megfelelő létszám esetén) rendelkezésre áll szivacs. Megközelíthetőség: Székesfehérvár-Börgönd repülőtér /LHBD/ Székesfehérvárról a Börgöndi úton kifelé haladva egyenesen a volt katonai laktanya és repülőtér bejáratához vezet az út. A kapun áthaladva táblák irányítanak az Albatrosz Repülő Egyesület által üzemeltetett repülőtérhez, épületekhez.',
        contactPerson: {
            name: 'Pula József',
            phone: '+36 20 978 9128',
            email: 'jozsef.pula@gmail.com',
            web: 'http://www.albatroszre.hu/',
            info: 'http://www.hungaryairport.hu/airport_data.php?id=4'
    }
    },
    {
        name: 'DropZone Miskolc',
        bio: 'Tavasztól késő őszig hétvégente szervezünk ejtőernyős ugrásokat, tandemugrást, sétarepültetést és ejtőernyős növendék képzést. A reptér címe: 3526 Miskolc, Repülőtéri út 7. GPS koordináta: N480820 E0204740',
        contactPerson: {
            name: 'Császár Zsolt',
            phone: '+36 20 968 3649',
            email: 'csaszarzs@freemail.hu',
            web: 'http://miskolcdropzone.hu/#!/',
            info: 'http://www.hungaryairport.hu/airport_data.php?id=27'
        }
        },
    {
        name: 'Gödöllő ',
        bio: 'A Gödöllő repülőtér, az ország egyik legrégebbi ugróterülete, bázisa. A repülőtér 1964-évtől kezdődően az ejtőernyős sport őshazájának tekinthető Egyenlőre  a ferihegyi nemzetközi forgalom közelsége, valamint az eseti légtérigénylések korszerűtlensége miatt a maximális magasság, amire felemelkedhetünk 1200-1500 méter. 2013-évtől várható a jogszabály megváltoztatása, ennek során a nagyobb ugratási magasságra történő emelkedés is biztosítva lesz.  Légijárműveinket a Sky Escort Hungary Aero Club üzemelteti. Géptípus: 2 db. AN-2, valamint tervbe van véve ismét egy kisgép beállítása. A tervezett üzemnapok idejei időszakonként közzé lesznek téve, figyelemebe véve a nagygépes ugróterületek működését. Légijárműveinkkel elsősorban a sport és az alapképzést szeretnénk elősegíteni és támogatni, egy rendkívül baráti bekerülési ár kialakításával. Kizárólagos költségek összegét a mindenkori napi üzemanyag árak befolyásolják. Sportklubunk nem a profitra törekszik, ezzel is szeretné Gödöllőt ismét "élhető ugróterületté varázsolni". Mottó: Ha összeszerveztél 12 ugrót -  akár hétköznap is - telefonálj és máris kitoljuk az Ancsát a hangárból !',
        contactPerson: {
            name: 'Both Lajos',
            phone: '+36 30 934 3199',
            email: 'info@godolloairport.hu',
            web: '',
            info: 'http://www.hungaryairport.hu/airport_data.php?id=14'
       }
    },
    {
        name: 'Hajdúszoboszló ',
        bio: 'Repülőterünk földrajzi elhelyezkedése egyedülálló, a város közelsége és hangulata miatt. Havi egy An-2 üzemet, esetenként L-410 tábort szervezünk (évente egy vagy kettő). A városban szállás, étkeztetés könnyen megoldható. A repülőtéren szálláslehetőség korlátozott számban elérhető, büfé üzemel.  A hajdúszoboszlói reptér repülőnap rendezvényt is tart.',
        contactPerson: {
            name: 'Pintér Péter',
            phone: '+36 30 205 8390',
            email: 'pinterpalinka@t-online.hu',
            web: 'http://www.hajdufly.hu/',
            info: 'http://www.hungaryairport.hu/airport_data.php?id=17'
       }
    },  
    {
        name: 'Kaposújlak ',
        bio: 'A Kapos DZ egész éves nyitva tartással várja az ugrókat, ugrási lehetőség  L-410-ből (1db), C-182-ből (3 db), C-185-ből (1 db) és C-206-ból (1 db) van. Kaposújlak Kaposvártól kb. 5 km-re található, Nagykanizsa felé (61-es út).',
        contactPerson: {
            name: 'Boross Attila',
            phone: '+36 20 777 9032',
            email: 'attila.boross@kaposdz.hu',
            web: 'https://kaposdz.hu/en',
            info: 'http://hungaryairport.hu/airport_data.php?id=21'
       }
    },  
    {
        name: 'Nyíregyháza ',
        bio: 'A Nyírségi Ejtőernyős Klub szervezésében, Nyíregyháza Repülőterén hétvégenként szervezünk ejtőernyős üzemeket. Az ugrásokat AN-2 és PZL-101 típusú repülőgépekből lehet végrehajtani. Az AN-2 3000 m magasra jár.  Az ugróterület kiképzésekre és tandemugrásokra is ad lehetőséget. Az üzemek időpontjait a klub honlapján és a facebook oldalán tesszük közzé, feliratkozóknak e-mail-ben küldjük meg, de telefonon is érdeklődhettek. Az ugrásokat érvényes okmányokkal lehet végrehajtani, a sportoló szakmai vezetőjének hozzájárulása és felügyelete mellett.',
        contactPerson: {
            name: 'Figeczky Attila',
            phone: '+36 30 248 8803',
            email: 'nyheje@gmail.com',
            web: 'http://www.ejtoernyos.sport.hu/',
            info: 'http://www.hungaryairport.hu/airport_data.php?id=29'
       }
    },  
    {
        name: 'Siófok-Kiliti ',
        bio: 'Versenyek, bemutatók helyszíne. Siófokon található, a Balaton part mellett. Géptípus: Mi-8 helikopter',
        contactPerson: {
            name: 'Setela Csaba',
            phone: '+36 70 579 6650',
            email: 'c.setela@articgroup.hu',
            web: 'https://skydivebalaton.com/Hu/',
            info: 'http://www.hungaryairport.hu/airport_data.php?id=36'
       }
    },  
    {
        name: 'Szeged ',
        bio: 
        <div>
            <p>Szeged repülőtér a város szélében fekszik, az 55-ös út mellett található. Könnyen megközelíthető az M5-ös autópálya 166-os lejárójáról. (55-ös út, Bajai út)</p>
            <br></br>
            <ul>
                <h2>Elérhető szolgáltatásaink - Cessna 182es típusú repülőgép:</h2>
                <li>- Sétarepülés: 10.000.- Ft/fő (max 3 fő/felszállás; 15 perc; Szeged város légterében)</li>
                <li>- Tandem ejtőernyős ugrás: 53.000.- Ft-tól</li>
                <li>- Ejtőernyős oktatás: kezdők kiképzése, valamint célbaugró képzés</li>
                <li>- Ejtőernyős üzemek szervezése (szombat vasárnap, és kellő létszám esetén hétköznap is)</li>
                <li>- Szálláslehetőség: a repülőtéren lehet sátrazni, kulturált tusoló, WC, és áramvételi lehetőség rendelkezésre áll.</li>
            </ul>
            <br></br>
            <p>A repülőtér területén vasárnap kivételével minden nap büfé üzemel.</p>
        </div>,
        contactPerson: {
            name: 'Csorba József',
            phone: '70/378-05-44',
            email: 'csorbajozsef67@gmail.com',
            web: 'https://tandemszeged.hu/',
            info: ''
       }
    },  

    {
        name: 'Szolnok-Szandaszőlős',
        bio: 
        <div>
            <p>Szolnok-Szandaszőlős repülőtér Szolnok kertvárosi területén, az élő- és holt Tisza között fekszik.</p>
            <p>Közvetlenül mellette bevásárlóközpont található.</p>
            <br></br>
            <ul>
                <h2>Elérhető szolgáltatásaink - Cessna 182es típusú repülőgép:</h2>
                <li>- Sétarepülés: 10.000.- Ft/fő (max 3 fő/felszállás; 15 perc; Szolnok város légterében)</li>
                <li>- Tandem ejtőernyős ugrás: 53.000.- Ft-tól</li>
                <li>- Ejtőernyős oktatás: kezdők kiképzése, valamint célbaugró képzés</li>
                <li>- Ejtőernyős üzemek szervezése (szombat vasárnap, és kellő létszám esetén hétköznap is)</li>
                <li>- Szálláslehetőség: a repülőtéren lehet sátrazni, kulturált tusoló, WC, és áramvételi lehetőség rendelkezésre áll.</li>
            </ul>
            <br></br>
        </div>,
        contactPerson: {
            name: 'Csorba József',
            phone: '70/378-05-44',
            email: 'csorbajozsef67@gmail.com',
            web: 'https://tandemzona.hu/',
            info: ''
       }
    },  
]