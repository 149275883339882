import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import { Calendar } from '@fullcalendar/core';
import moment from 'moment';
import 'moment/locale/hu' 

import './calendar.css'

export default function Calendar2 () {

  const [currentEvents, setCurrentEvents] = useState([])

  useEffect(() => {
    renderCalendar();
  }, [])

  moment.locale('hu')

  function renderSidebar() {
    return (
      <div className='calendar-sidebar'>
          <h2>Minden esemény ({currentEvents.length})</h2>
          <ul>
            {currentEvents.map(renderSidebarEvent)}
          </ul>
      </div>
    )
  }

  const handleEvents = (events) => {
    setCurrentEvents(events)
  }

/*   function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  } */
  
  function renderSidebarEvent(event) {
    return (
      <li key={event.id}>
        <b>{formatDate(event.start, {year: 'numeric', month: 'long', day: 'numeric'})}</b>
        <i>{event.title}</i>
      </li>
    )
  }

   function renderCalendar() {
    var calendarEl = document.getElementById('calendar');

    var calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin ],
      initialView: 'dayGridMonth',
      googleCalendarApiKey: 'AIzaSyCOvnP6AhqS4jHQAsENVGasxojJU4ASAb4',
      events: {
        googleCalendarId: 's556h5261j9kem1b708r126k20@group.calendar.google.com',
      },
      eventsSet: handleEvents,
      eventClick: function(arg) {

        // opens events in a popup window
        window.open(arg.event.url, '_blank', 'width=700,height=600');
  
        // prevents current tab from navigating
        arg.jsEvent.preventDefault();
      },
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      titleFormat: { year: 'numeric', month: 'long', day: 'numeric' } ,
      buttonText: {
        today:    'mai nap',
        month:    'aktuális hónap',
        week:     'heti nézet',
        day:      'napi nézet',
        list:     'list'
      },
      eventBackgroundColor: 'rgb(25, 180, 121)',
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false
      } 
    });

    calendar.render();
  }


  return (
    <>
    <Helmet>
      <title>Eseménynaptár - Skydive.hu</title>
      <meta name="description" content="events" />
    </Helmet>
    <div className='calendar-container'>
      {renderSidebar()}      
      <div id='calendar' className='calendar-main'></div>
    </div>
    </>
  ) 
}

