export const members = [
    {
    name: 'Albatrosz',
    img: 'https://is5-ssl.mzstatic.com/image/thumb/Purple128/v4/95/2f/18/952f1822-5439-6854-e16e-dafd6b1227cd/source/512x512bb.jpg',
    bio: 'Az Albatrosz Repülő Egyesület négy szakosztállyal rendelkezik.',
    fullname: 'Albatrosz repülő egyesület',
    departments: [
     { name: 'Merevszárnyú és Vitorlázó szakosztály: 38 fő'},
     { name: 'Ejtőernyős szakosztály: 14 fő' },
     { name: 'Sárkányrepülő szakosztály: 11 fő'},
     { name: 'Öreg Sasok szakosztály: 39 fő'},
    ],
    education: 'Bekötött',
    contactPerson: {
        name: 'Pula József',
        phone: '+36 20 978 9128',
        email: 'jozsef.pula@gmail.com',
        web: 'http://www.albatroszre.hu/'
    },
      ugro: [
          {label: 'Börgönd'},
          {label: 'Kaposújlak'}
      ],
      contact: {
          address: '8000 Székesfehérvár, Radványi út 6.',
          map: {
              lat: 47.194110,
              lng: 18.459900
          }
      },
    },
   
     
       
            {
              name: 'Hajdúszoboszló',
              img: 'https://previews.123rf.com/images/16tigers/16tigers1408/16tigers140800020/32675683-braking-parachute-jumper.jpg',
              bio: '',
              fullname: 'Aeroclub Hajdúszoboszló',
              departments: [
               { name: 'Taglétszám: 19 fő'},
               { name: 'Eredmények: formaugrásban minősített sportolók száma 5 fő' },
               { name: 'Versenyeredmények: 2 - 5. helyezések évekre visszamenőleg'},
               { name: 'Rendezvény és versenyszervezés: repülőnapok, Amnézia páros formaugró verseny minden évben, L-410 tábor házigazdája évi rendszerességgel'},
               { name: 'Célok: havi egy AN-2 üzem és kisgép üzemeltetés'},  
            ],
              education: 'AFF | Bekötött',
              contactPerson: {
                  name: 'Pintér Péter',
                  phone: '+36 30 205 8390',
                  email: 'pinterpalinka@t-online.hu',
                  web: 'http://www.hajdufly.hu/'
              },
                ugro: [
                    {label: 'Kaposújlak'},
                    {label: 'Kaposújlak'},
                    {label: 'Nyíregyháza'},
                ],
                contact: {
                    address: '4200 Hajdúszoboszló, Repülőtér',
                    map: {
                        lat: 47.4595832,
                        lng: 21.390262
                    }
                },
              },

              {name: 'IX. KER.',
              img: 'https://previews.123rf.com/images/16tigers/16tigers1408/16tigers140800020/32675683-braking-parachute-jumper.jpg',
              bio: 'Egyesületünk 1957-ben alakult. Célunk, hogy ismét vezető szerepet foglaljunk el a Magyar Ejtőernyős társadalomban elsősorban a versenyeken. Az elmúlt években kevés versenyen vettünk részt. Ez elsősorban az egyesület anyagi helyzetével függ össze. Ezért elsődlegesen a versenyzői utánpótlásunkat szeretnénk kinevelni. Az egyesület eredményei: számos klasszikus Magyar Bajnok versenyzővel büszkélkedhetünk. Formaugrásban a IX.ker. csapata volt az első magyar résztvevő a FU Világbajnokságokon. FU versenyzőink 1991-1995-ig voltak Magyar Bajnokok. Jelenlegi regisztrált taglétszámunk 23 MRSZ/MESZ tag, és 35 pártoló tag. Minden héten keddenként klubnapot tartunk a Ferenc krt. 28. sz. alatti helységünkben. Ezzel is szeretnénk az egyesületi életet fellendíteni.',
              fullname: 'IX. Kerületi Ejtőernyős Klub SE',
              departments: [
              ],
              education: 'Bekötött',
              contactPerson: {
                  name: 'Tóth (1) Gábor',
                  phone: '+36 70 330 5637',
                  email: 'tandem1gabor@gmail.com',
                  web: 'https://www.blueskies.hu/'
              },
                ugro: [
                    {label: 'Kaposújlak'}
                ],
                contact: {
                    address: '1092 Budapest, Ferenc krt. 28.',
                    map: {
                        lat: 47.483740,
                        lng: 19.068860
                    }
                },
              },
              {name: 'Kvasz András',
              img: 'https://previews.123rf.com/images/16tigers/16tigers1408/16tigers140800020/32675683-braking-parachute-jumper.jpg',
              bio: 'Egyesületünk több mint negyven éve van jelen a hazai ejtőernyőzésben, számtalan magyar bajnokkal rendelkezünk a klasszikus szakág területén. A Békéscsabai repülőtér Békéscsaba és Gyula között található. Jelenleg alacsony magasságból történő ugrások végrehajtására van lehetőségünk. 14 fő számára van szállás lehetőség a repülőtér területén belül. Oktatást a BKLC képzési területen végzünk. A célbaugrás gyakorlására rendelkezünk megfelelő méretű és minőségű szivaccsal, kijelzővel. A válogatott keret edzőtáborainak ideje alatt a tagok szívesen segítenek mindenkinek. A swoop ugrások végrehajtásához a megfelelő feltételek mellett Magyar Krisztián vállalja a szakmai segítségnyújtást a szervezett táborok keretében. Minden érdeklődőt szeretettel várunk!',
              fullname: 'Kvasz András Repülő és Ejtőernyős Egyesület',
              departments: [

              ],
              education: 'Bekötött',
              contactPerson: {
                  name: 'Mengyán László',
                  phone: '+36 30 214 1102',
                  email: 'kefe1@t-online.hu',
                  web: ''
              },
                ugro: [
                    {label: ''}
                ],
                contact: {
                    address: '5600 Békéscsaba, Repülőtér',
                    map: {
                        lat: 46.676569,
                        lng: 21.161086
                    }
                },
              },
              {name: 'MBSE',
              img: 'https://previews.123rf.com/images/16tigers/16tigers1408/16tigers140800020/32675683-braking-parachute-jumper.jpg',
              bio: 'Az Egyesület a Magyar ejtőernyős és bázisugró sport, illetve a magyar bázisugrók, és ejtőernyősök támogatását tekinti céljának, a sportágak hazai népszerűsítését, és a kor követelményeinek megfelelő oktatását tűzi ki feladatául, továbbá hazai és nemzetközi versenyeket szervez. Egyesületünk hazánkban a legdinamikusabban fejlődő ejtőernyős klub, köszönhetően a színvonalas képzéseknek és baráti légkörű atmoszférának, taglétszámunk folyamatos növekedésével, pár év alatt a legnagyobb ejtőernyős sportegyesületévé váltunk, amely jelenleg több mint 90 tagot számlál. Tavasztól őszig folyamatos ejtőernyős képzéseket tartunk (AFF) és az ejtőernyőzés iránt érdeklődőknek tandemugrási lehetőségeket kínálunk. Minden hazai ejtőernyős versenyen (RW, FF, WINGSUIT, Kupolakezelő, Célraszálló, Speedstar, Speedway) képviseltetjük magunkat, egyesületünk oktatói több nemzeti bajnoki címmel rendelkeznek és a hazai ejtőernyőzés élvonalába tartoznak.',
              fullname: 'Magyar Bázisugró Sport Egyesület',
              departments: [
              ],
              education: 'AFF',
              contactPerson: {
                  name: 'Szörényi Csaba',
                  phone: '+36 30 280 4854',
                  email: 'csaber@mbse.hu',
                  web: 'http://mbse.hu/'
              },
                ugro: [
                    {label: 'Siófok-Kiliti'}
                ],
                contact: {
                    address: '1025 Budapest, Törökvész út 95-97/B',
                    map: {
                        lat: 47.530630,
                        lng: 19.001680
                    }
                },
              },
            
              {name: 'Sky Escort',
              img: 'https://previews.123rf.com/images/16tigers/16tigers1408/16tigers140800020/32675683-braking-parachute-jumper.jpg',
              bio: 'A Sky Escort Aero Klub sport tevékenysége a repülés és ejtőernyős képzés. Az egyesület létszáma 60-70 fő.',
              fullname: 'Sky Escort Aeró Klub',
              departments: [

              ],
              education: 'Bekötött',
              contactPerson: {
                  name: 'Ari Bálint',
                  phone: '+36 20 261 0019',
                  email: 'aribalint.64@indamail.hu',
                  web: ''
              },
                ugro: [
                    {label: 'Gödöllő'},
                    {label: 'Kaposújlak'},
                    {label: 'Siófok-Kiliti'}
                ],
                contact: {
                    address: '',
                    map: {
                        lat: 47.166401,
                        lng: 19.413937
                    }
                },
              },
            
             

              {name: 'Tériszony',
              img: 'https://previews.123rf.com/images/16tigers/16tigers1408/16tigers140800020/32675683-braking-parachute-jumper.jpg',
              bio: 'A Tériszony Sportegyesület tevékenysége az ejtőernyőz képzés és a tandemugrás.',
              fullname: 'Tériszony Sportegyesület',
              departments: [
              ],
              education: 'Bekötött',
              contactPerson: {
                  name: 'Gyémánt János',
                  phone: ' +36 20 226 2526',
                  email: 'gyemusz@gmail.com',
                  web: ''
              },
                ugro: [
                    {label: 'Gödöllő'},
                    {label: 'Kaposújlak'},
                    {label: 'Szeged'},
                ],
                contact: {
                    address: '2096 Üröm, Tücsök köz 1/B',
                    map: {
                        lat: 47.58568,
                        lng: 18.99907
                    }
                },
              },

          
             
  ]