import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";

import translationEN from './locales/en/translation.json';
import translationHU from './locales/hu/translation.json'

// the translations
const resources = {
    en: {
      translation: translationEN
    },
    hu: {
      translation: translationHU
    }
  };
i18n
  .use(detector)
  .use(backend)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "hu",
    fallbackLng: "hu", // use en if detected lng is not available


    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;