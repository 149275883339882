
import React, {useState, useEffect, useCallback} from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns'; 
import {  MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18n from './i18n';
import { withNamespaces } from 'react-i18next';
import ScrollTop from "./components/ScrollTop"
import Menubar from './components/menubar'
import Home from './Pages/home'
import Blog from './Pages/blog'
import Footer from './components/footer';
import MembersInfo from './Pages/member-info';
import Calendar from './Pages/eventCalendar/calendar'
import DocumentCollection from './Pages/documentCollection';
import Galery from './Pages/galery';
import './App.css'; 
import { EventContext } from './context/event-context';
import 'antd/dist/antd.css';


import moment from 'moment';
import { UserContext } from './context/UserContext';


function App({ t }) {

/*   const [language, setLanguage] = useState('');

  const [password, setPassword] = useState() */
  const [event, setEvent] = useState([])
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const storedData = JSON.parse(window.localStorage.getItem('LOGGED_IN'));
    setLoggedIn(storedData);
  }, [])

  useEffect(() => {
      localStorage.setItem("LOGGED_IN", JSON.stringify(loggedIn))

  }, [loggedIn])



const addEvent = useCallback((event) => {
  setEvent(event)
}, []);

/* 
useEffect(() => {
  let lng = localStorage.getItem('lng');
  setLanguage(lng)
  changeLanguage(lng)
}, [])

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  setLanguage(lng);
  localStorage.setItem('lng', lng)
} */

let gapi = window.gapi
const getEvents = () => {
  function start() {
    gapi.client
      .init({
        apiKey: 'AIzaSyCOvnP6AhqS4jHQAsENVGasxojJU4ASAb4'
      })
      .then(function() {
        return gapi.client.request({
          path: `https://www.googleapis.com/calendar/v3/calendars/s556h5261j9kem1b708r126k20@group.calendar.google.com/events?maxResults=11&orderBy=updated&timeMin=${moment().toISOString()}&timeMax=${moment().add(1, 'months')
            .endOf("month")
            .toISOString()}`
        });
      })
      .then(
        response => {
          let events = response.result.items;
          if (events.length > 0) {
            setEvent(events)
          } else {
          }
        }
      );
  }
  gapi.load("client", start);
}


useEffect(() => {
  getEvents()
}, [])


  return (
    <EventContext.Provider value={{actualEvent: event, addEvent: addEvent}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}> 
      <UserContext.Provider value={{loggedIn, setLoggedIn}}> 
        <div className="App">
          <Router>
            <ScrollTop />
            <Menubar /> 
            <Switch>
            <Route path="/" exact>
              <Home />
            </Route> 
            <Route path="/hirek">
              <Blog />
            </Route>
            <Route path="/tagszervezetek">
              <MembersInfo />
            </Route>
            <Route path="/esemenyek">
              <Calendar />
            </Route> 
            <Route path="/letoltheto">
              <DocumentCollection />
            </Route>  
            <Route path="/galeria">
              <Galery />
            </Route>    
            <Redirect to="/"/> 
            </Switch>
            <Footer />
          </Router> 
        </div> 
        </UserContext.Provider>
      </MuiPickersUtilsProvider>
    </EventContext.Provider>  
  )
}

export default App;
